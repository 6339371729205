import React, { Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/logo.png';
import iosQrCode from "../assets/ios.png";
import androidQrCode from "../assets/android.png";
import '../App.css';

class FreeTrial extends Component {
    render() {
      return (
          <div className="freetrial">
            <div className="App-header">
                <div className="top">
                    <a><img className="logo" src={logo} /></a>
                    <Link to="/">产品介绍</Link>
                    <a href="https://student.fitback.dealsports.cn/wx/#/public/fatdonate/introduce" target="_blank">脂肪收购季</a>
                </div>
                <div style={{ marginTop: "50px" }}>
                    Fitback管家扫码下载
                </div>
                <div className="builds">
                    <div className="build">
                        <img src={iosQrCode} width="172" />
                        IOS版本
                    </div>
                    <div className="build">
                        <img src={androidQrCode} width="172" />
                        安卓版本
                    </div>
                </div>
            </div>
            <div className="introduce">
                <p>“Fitback管家”是一款专门服务于健身工作室的智能化移动运营管理软件，让工作室老板/店长等管理人员随时随地在手机上实现店铺的运营管理，能极大降低运营成本，提高运营效率。</p>
                <p>1. 学员管理：店铺所有员工资料、体况、课程、上课情况等信息管理。</p>
                <p>2. 员工管理：店铺所有员工资料、课程、上课情况、业绩等信息管理。</p>
                <p>3. 课程管理：店铺课程设置、排期。</p>
                <p>4. 课表管理：店铺课表查询及日程安排查询。</p>
                <p>5. 报表管理：店铺收入、课时、学员、教练等统计分析数据。</p>
                
            </div>
          </div>
      );
    }
  }
  
  export default FreeTrial;
  