import React, { Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/logo.png';
import bigLogo from "../assets/bigLogo.png";
import freeTrial from "../assets/freeTrial.png";
import professional from "../assets/professional.png";
import dataIcon from "../assets/data.png";
import unifyIcon from "../assets/unify.png";
import functionIcon from "../assets/function.png";
import moduleShop from "../assets/module-shop.png";
import moduleCoach from "../assets/module-coach.png";
import moduleMember from "../assets/module-member.png";
import moduleOrder from "../assets/module-order.png";
import moduleCast from "../assets/module-cast.png";
import partner from "../assets/partner.png";
import wechat from "../assets/wechat.png"; 
import '../App.css';

class Home extends Component {
    render() {
      return (
        <div className="App">
            <div className="App-header">
            <div className="top">
                <a><img className="logo" src={logo} /></a>     
                <a className="current">产品介绍</a>
                <a href="https://student.fitback.dealsports.cn/wx/#/public/fatdonate/introduce" target="_blank">脂肪收购季</a>
                <Link to="freetrial" className="freeTrial">免费试用</Link>
            </div>
            <div style={{marginTop: "50px"}}><img src={bigLogo} width="250" /></div>
            <div>
                <Link to="freetrial"><img src={freeTrial} width="167" className="freeTrialBeta" /></Link>
            </div>
            </div>

            <div className="feature">
            <div className="item">
                <img className="icon" src={professional} />
                <div className="title">专业</div>
                <div className="detail">国家级健身专家顾问团队，打造出业内最专业、最便捷、最能体现私教价值的健身管理系统</div>
            </div>
            <div className="item">
                <img className="icon" src={dataIcon} />
                <div className="title">数据</div>
                <div className="detail">系统配备的新一代智能硬件让健身全程数据化，重新定义健身运动，以最低成本助力场馆数字化升级</div>
            </div>
            <div className="item">
                <img className="icon" src={unifyIcon} />
                <div className="title">一体</div>
                <div className="detail">店铺运营、教练工作、会员服务、心率训练，一套系统全打通，降本增效，超高性价比</div>
            </div>
            </div>

            <div className="functions">
            <div className="primary-title">系统功能模块</div>
            <div className="second-title">专业私教服务系统</div>
            <div className="items">
                <div className="item">
                <img src={functionIcon} />
                <div className="content">会员管理</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">定制目标</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">课程管理</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">日程管理</div>
                </div>
            </div>
            <div className="items">
                <div className="item">
                <img src={functionIcon} />
                <div className="content">微信约课</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">线上安排</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">智能上课</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">教练管理</div>
                </div>
            </div>
            <div className="items">
                <div className="item">
                <img src={functionIcon} />
                <div className="content">工资计算</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">数据报表</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">电子签课</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">微信提醒</div>
                </div>
            </div>
            <div className="items">
                <div className="item">
                <img src={functionIcon} />
                <div className="content">健身报告</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">心率监测</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">投屏互动</div>
                </div>
                <div className="item">
                <img src={functionIcon} />
                <div className="content">营销推荐</div>
                </div>
            </div>
            </div>

            <div className="modules">
            <img src={moduleShop} />
            <img src={moduleCoach} />
            <img src={moduleMember} />
            <img src={moduleOrder} />
            <img src={moduleCast} />
            </div>

            <div className="partner">
                <div className="primary-title">合作品牌</div>
                <div className="second-title">部分合作品牌</div>
                <img src={partner} />
            </div>

            <div className="footer">
            <div className="contact">
                <div className="title">联系我们</div>
                <p>工作时间：9:30-18:30（工作日）</p>
                <p>联系电话：18917011549</p>
                <p>商务合作：career@dealsports.cn</p>
                <p>上海市浦东新区张江镇博云路111号爱酷空间304室</p>
            </div>
            <div className="wechat">
                <div className="title">官方微信</div>
                <img src={wechat} />
            </div>
            </div>

        </div>
      );
    }
  }
  
  export default Home;
  